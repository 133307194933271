<template>
	<transition>
		<div class="container">
			<div class="space-y-10">
				<div>
					<table class="table_list">
						<colgroup>
							<col style="width:20%;" />
							<col style="width:20%;" />
							<col style="width:15%;" />
							<col style="width:15%;" />
							<col style="width:15%;" />
							<col style="width:15%;" />
						</colgroup>
						<thead class="inner-header">
							<tr>
								<th style="background-color:rgba(0,0,0,.04); border-right: 1px solid rgba(0,0,0,.12);">문항구분</th>
								<th style="background-color:rgba(0,0,0,.04); border-right: 1px solid rgba(0,0,0,.12);">문항구분상세</th>
								<th style="background-color:rgba(0,0,0,.04); border-right: 1px solid rgba(0,0,0,.12);">난이도 높음</th>
								<th style="background-color:rgba(0,0,0,.04); border-right: 1px solid rgba(0,0,0,.12);">난이도 보통</th>
								<th style="background-color:rgba(0,0,0,.04); border-right: 1px solid rgba(0,0,0,.12);">난이도 낮음</th>
								<th style="background-color:rgba(0,0,0,.04);">총 문항수</th>
							</tr>
						</thead>
						<tbody>
							<tr class="h-12">
								<td style="border-right: 1px solid rgba(0,0,0,.12);">
									<div class="border-none">
										<DxSelectBox
											placeholder="전체"
											styling-mode="outlined"
											:items="codes.examQuestionDivisionCd.dataSource"
											display-expr="codeNm"
											value-expr="codeId"
											v-model="formData.questionDivisionCd"
										>
										</DxSelectBox>
									</div>
								</td>
								<td style="border-right: 1px solid rgba(0,0,0,.12);">
									<div class="border-none">
										<DxSelectBox
											placeholder="전체"
											styling-mode="outlined"
											:items="getQuestionTypeDetailList(formData.questionDivisionCd)"
											display-expr="codeNm"
											value-expr="codeId"
											v-model="formData.questionDivisionDetailCd"
										>
										</DxSelectBox>
									</div>
								</td>
								<td style="border-right: 1px solid rgba(0,0,0,.12);">
									<div class="border-none">
										<DxNumberBox
											v-model="formData.highCnt"
											styling-mode="outlined"
											:min="0"
											invalid-date-message="입력 데이터가 유효하지 않습니다."
										>
											<DxValidator validation-group="validationSearch">
												<DxRequiredRule />
											</DxValidator>
										</DxNumberBox>
									</div>
								</td>
								<td style="border-right: 1px solid rgba(0,0,0,.12);">
									<div class="border-none">
										<DxNumberBox
											v-model="formData.middleCnt"
											styling-mode="outlined"
											:min="0"
											invalid-date-message="입력 데이터가 유효하지 않습니다."
										>
											<DxValidator validation-group="validationSearch">
												<DxRequiredRule />
											</DxValidator>
										</DxNumberBox>
									</div>
								</td>
								<td style="border-right: 1px solid rgba(0,0,0,.12);">
									<div class="border-none">
										<DxNumberBox
											v-model="formData.lowCnt"
											styling-mode="outlined"
											:min="0"
											invalid-date-message="입력 데이터가 유효하지 않습니다."
										>
											<DxValidator validation-group="validationSearch">
												<DxRequiredRule />
											</DxValidator>
										</DxNumberBox>
									</div>
								</td>
								<td>
									<!-- <DxNumberBox v-model="sumCnt" :styling-mode="config.stylingMode" :read-only="true"> </DxNumberBox> -->
									{{ sumCnt }}
								</td>
							</tr>
						</tbody>
					</table>

					<div class="mt-6 text-center space-x-2">
						<DxButton
							text="문항 자동 추출"
							:width="120"
							:height="40"
							class="default filled txt_S medium"
							@click="onQuestionData"
						/>
						<DxButton
							text="초기화"
							class="btn_XS white filled txt_S medium"
							:width="90"
							:height="40"
							@click="resetQuestionData"
						/>
					</div>
				</div>

				<div>
					<table class="table_list">
						<colgroup>
							<col style="width:20%;" />
							<col style="width:20%;" />
							<col style="width:20%;" />
							<col style="width:20%;" />
							<col style="width:auto;" />
						</colgroup>
						<thead class="inner-header">
							<tr>
								<th style="background-color:rgba(0,0,0,.04); border-right: 1px solid rgba(0,0,0,.12);">객관식</th>
								<th style="background-color:rgba(0,0,0,.04); border-right: 1px solid rgba(0,0,0,.12);">O/X</th>
								<th style="background-color:rgba(0,0,0,.04); border-right: 1px solid rgba(0,0,0,.12);">단답형</th>
								<th style="background-color:rgba(0,0,0,.04); border-right: 1px solid rgba(0,0,0,.12);">서술형</th>
								<th style="background-color:rgba(0,0,0,.04);">총 추출 문항수</th>
							</tr>
						</thead>
						<tbody>
							<tr class="h-12">
								<td style="border-right: 1px solid rgba(0,0,0,.12);">
									<!-- <DxNumberBox v-model="formData.multiChoice" :styling-mode="config.stylingMode" :read-only="true">
									</DxNumberBox> -->
									{{ formData.multiChoice != null ? formData.multiChoice : '-' }}
								</td>
								<td style="border-right: 1px solid rgba(0,0,0,.12);">
									<!-- <DxNumberBox v-model="formData.oxChoice" :styling-mode="config.stylingMode" :read-only="true">
									</DxNumberBox> -->
									{{ formData.oxChoice != null ? formData.oxChoice : '-' }}
								</td>
								<td style="border-right: 1px solid rgba(0,0,0,.12);">
									<!-- <DxNumberBox v-model="formData.shortAnswer" :styling-mode="config.stylingMode" :read-only="true">
									</DxNumberBox> -->
									{{ formData.shortAnswer != null ? formData.shortAnswer : '-' }}
								</td>
								<td style="border-right: 1px solid rgba(0,0,0,.12);">
									<!-- <DxNumberBox v-model="formData.descriptiveForm" :styling-mode="config.stylingMode" :read-only="true">
									</DxNumberBox> -->
									{{ formData.descriptiveForm != null ? formData.descriptiveForm : '-' }}
								</td>
								<td>
									<!-- <DxNumberBox v-model="formData.sumCnt" :styling-mode="config.stylingMode" :read-only="true">
									</DxNumberBox> -->
									{{ formData.totalCnt != null ? formData.totalCnt : '-' }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { DxButton } from 'devextreme-vue/button';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxNumberBox } from 'devextreme-vue/number-box';
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
import validationEngine from 'devextreme/ui/validation_engine';
import { isSuccess } from '@/plugins/common-lib';

export default {
	components: {
		DxButton,
		DxTextBox,
		DxSelectBox,
		DxNumberBox,

		DxRequiredRule,
		DxValidator,
	},
	props: {},
	watch: {
		'formData.questionDivisionCd': function(newVal, oldVal) {
			this.formData.questionDivisionDetailCd = null;
		},
		/** @description: 넘어갈 데이터 */
		finalData: {
			handler(val) {
				this.$emit('input', val);
			},
			deep: true,
		},
	},
	data() {
		return {
			codeMap: {},
			config: {
				stylingMode: 'filled', //[outlined, filled, underlined]
			},
			codes: {
				examQuestionDivisionCd: {
					dataSource: [],
					displayExpr: 'codeNm',
					valueExpr: 'codeId',
				},
				examQuestionDivisionDetail: {
					dataSource: [],
					displayExpr: 'codeNm',
					valueExpr: 'codeId',
				},
			},
			formData: {
				questionDivisionCd: null,
				questionDivisionDetailCd: null,
				highCnt: null,
				middleCnt: null,
				lowCnt: null,
				multiChoice: null,
				oxChoice: null,
				shortAnswer: null,
				descriptiveForm: null,
			},
			finalData: [],
		};
	},
	computed: {
		getQuestionTypeDetail1List() {
			return this.$_getCode('ewm_edu_exam_question_division_001');
		},
		getQuestionTypeDetail2List() {
			return this.$_getCode('ewm_edu_exam_question_division_002');
		},
		sumCnt() {
			return this.formData.highCnt + this.formData.middleCnt + this.formData.lowCnt
				? this.formData.highCnt + this.formData.middleCnt + this.formData.lowCnt
				: 0;
		},
	},
	methods: {
		/** @description : 추출 시뮬레이션 버튼 */
		async onQuestionData() {
			if (!validationEngine.validateGroup('validationSearch').isValid) {
				return;
			}
			// this.$set(this.formData, 'highCnt', this.formData.highCnt ? this.formData.highCnt : 0);
			// this.$set(this.formData, 'middleCnt', this.formData.middleCnt ? this.formData.middleCnt : 0);
			// this.$set(this.formData, 'lowCnt', this.formData.lowCnt ? this.formData.lowCnt : 0);

			let params = {};
			if (this.formData.questionDivisionCd && this.formData.questionDivisionDetailCd) {
				params = {
					questionDivisionCd: this.formData.questionDivisionCd,
					questionDivisionDetailCd: this.formData.questionDivisionDetailCd,
				};
			} else if (this.formData.questionDivisionCd) {
				params = {
					questionDivisionCd: this.formData.questionDivisionCd,
				};
			}

			let payload = {
				actionname: 'EWM_EXAM_SHEET_DETAIL_EXTRACTION_SELECT',
				data: params,
				loading: true,
			};

			let res = await this.CALL_EWM_API(payload);

			if (isSuccess(res)) {
				let levelHigh = this.formData.highCnt > res.data.data[0].levelUpperCnt ? false : true;
				let levelMiddle = this.formData.middleCnt > res.data.data[0].levelMiddleCnt ? false : true;
				let levelLow = this.formData.lowCnt > res.data.data[0].levelLowerCnt ? false : true;

				if (!levelHigh) {
					// this.$_Msg('높음 문항의 수가 부족합니다.');
					this.formData.highCnt = res.data.data[0].levelUpperCnt;
				}
				if (!levelMiddle) {
					// this.$_Msg('보통 문항의 수가 부족합니다.');
					this.formData.middleCnt = res.data.data[0].levelMiddleCnt;
				}
				if (!levelLow) {
					this.formData.lowCnt = res.data.data[0].levelLowerCnt;
				}
			}

			let params2 = {};
			if (this.formData.questionDivisionCd && this.formData.questionDivisionDetailCd) {
				params2 = {
					questionDivisionCd: this.formData.questionDivisionCd,
					questionDivisionDetailCd: this.formData.questionDivisionDetailCd,
					levelLowerCnt: this.formData.lowCnt,
					levelMiddleCnt: this.formData.middleCnt,
					levelUpperCnt: this.formData.highCnt,
				};
			} else if (this.formData.questionDivisionCd) {
				params2 = {
					questionDivisionCd: this.formData.questionDivisionCd,
					levelLowerCnt: this.formData.lowCnt,
					levelMiddleCnt: this.formData.middleCnt,
					levelUpperCnt: this.formData.highCnt,
				};
			} else {
				params2 = {
					levelLowerCnt: this.formData.lowCnt,
					levelMiddleCnt: this.formData.middleCnt,
					levelUpperCnt: this.formData.highCnt,
				};
			}

			let payload2 = {
				actionname: 'EWM_EXAM_SHEET_DETAIL_EXTRACT_LIST',
				data: params2,
				loading: true,
			};

			let res2 = await this.CALL_EWM_API(payload2);

			if (isSuccess(res2)) {
				let multiChoice = 0;
				let oxChoice = 0;
				let shortAnswer = 0;
				let descriptiveForm = 0;
				let totalCnt = 0;

				for (let item of res2.data.data) {
					if (item.answerTypeCd === 994) {
						multiChoice++;
					} else if (item.answerTypeCd === 995) {
						oxChoice++;
					} else if (item.answerTypeCd === 996) {
						shortAnswer++;
					} else if (item.answerTypeCd === 997) {
						descriptiveForm++;
					}
					totalCnt = multiChoice + oxChoice + shortAnswer + descriptiveForm;
				}
				this.$set(this.formData, 'multiChoice', multiChoice);
				this.$set(this.formData, 'oxChoice', oxChoice);
				this.$set(this.formData, 'shortAnswer', shortAnswer);
				this.$set(this.formData, 'descriptiveForm', descriptiveForm);
				this.$set(this.formData, 'totalCnt', totalCnt);

				this.finalData = res2.data.data;
			}
		},
		resetQuestionData() {
			this.formData.highCnt = null;
			this.formData.middleCnt = null;
			this.formData.lowCnt = null;
			this.formData.multiChoice = null;
			this.formData.oxChoice = null;
			this.formData.shortAnswer = null;
			this.formData.descriptiveForm = null;
			this.formData.totalCnt = null;
		},
		getQuestionTypeDetailList(cd) {
			return [
				{ codeId: null, codeNm: '전체' },
				...this.codes.examQuestionDivisionDetail.dataSource.filter(item => item.parentId === cd),
			];
		},
		/** @description : 셀렉트 박스 초기화 */
		async initCodeMap() {
			this.codeMap = await this.$_getCodeMapList('root_ewm_edu_exam_question_division');
		},
		createdData() {
			this.initCodeMap().then(() => {
				this.codes.examQuestionDivisionCd.dataSource = [
					{ codeId: null, codeNm: '전체' },
					...this.$_fetchCodesByDepth(this.codeMap['root_ewm_edu_exam_question_division'], 2),
				]; //문항구분

				this.codes.examQuestionDivisionDetail.dataSource = this.$_fetchCodesByDepth(
					this.codeMap['root_ewm_edu_exam_question_division'],
					3,
				);
			});
		},
	},
	created() {
		this.createdData();
	},
	mounted() {},
};
</script>
<style scoped>
.border-none * {
	border: none;
	width: auto;
}
</style>
